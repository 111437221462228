import React, { useState, useEffect } from 'react';
import { useCMS } from '../contexts/CMSContext';
import { useTranslation } from 'react-i18next';
import TaskModal from './TaskModal';

const SuggestedTasks = () => {
  const { t } = useTranslation();
  const cmsContent = useCMS();
  const [suggestedTasks, setSuggestedTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    category: '',
    location: '',
    taskType: '',
    familyFriendly: '',
  });
  const [selectedTask, setSelectedTask] = useState(null);

  const categories = cmsContent.interestedCategories || [
    t('categories.administration'),
    t('categories.animal_care'),
    t('categories.anything_with_food'),
    t('categories.educational'),
    t('categories.environmental_cultural'),
    t('categories.family_friendly'),
    t('categories.maintenance'),
    t('categories.retail'),
    t('categories.social_care_giving'),
    t('categories.sports_related'),
    t('categories.other')
  ];

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const userObject = JSON.parse(userString);
      setUser(userObject);
    }
  }, []);

  const fetchSuggestedTasks = async () => {
    if (!user) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(`https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/users/tasks/suggestions?user_id=${user.userId}`);
      if (!response.ok) {
        throw new Error(t('errors.failed_to_fetch_tasks'));
      }
      const data = await response.json();
      setSuggestedTasks(data);
      setFilteredTasks(data);
    } catch (err) {
      console.error(t('errors.fetch_tasks_failed'), err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSuggestedTasks();
  }, [user]);

  const refreshTasks = async () => {
    await fetchSuggestedTasks();
  };

  useEffect(() => {
    const filtered = suggestedTasks.filter((task) => {
      const matchesSearch = task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            task.description.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = !filters.category || task.tags.includes(filters.category);
      const matchesLocation = !filters.location || 
                              (filters.location === 'Remote' && task.is_remote) ||
                              (filters.location === 'On-site' && !task.is_remote);
      const matchesTaskType = !filters.taskType || 
                              (filters.taskType === 'One-time' && task.is_one_time) ||
                              (filters.taskType === 'Ongoing' && task.is_ongoing);
      const matchesFamilyFriendly = !filters.familyFriendly || 
                                    (filters.familyFriendly === 'Yes' && task.is_family_friendly) ||
                                    (filters.familyFriendly === 'No' && !task.is_family_friendly);

      return matchesSearch && matchesCategory && matchesLocation && matchesTaskType && matchesFamilyFriendly;
    });

    setFilteredTasks(filtered);
  }, [searchQuery, filters, suggestedTasks]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const handleLearnMore = (task) => {
    setSelectedTask(task);
  };

  const handleCloseModal = () => {
    setSelectedTask(null);
  };

  if (isLoading) {
    return <div className="text-center py-12">{t('loading')}</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-red-500">{t('errors.fetch_tasks_failed')}</div>;
  }

  if (!user) {
    return <div className="text-center py-12">{t('errors.login_to_see_tasks')}</div>;
  }

  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">{t('suggested_tasks.title')}</h2>

        <div className="mb-6">
          <input
            type="text"
            className="w-full p-4 rounded-lg shadow-lg bg-gray-100 text-gray-700 focus:outline-none focus:ring-2 focus:ring-yellow-400 border border-gray-300"
            placeholder={t('suggested_tasks.search_placeholder')}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.category')}</label>
            <select
              name="category"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.category}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all_categories')}</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.location')}</label>
            <select
              name="location"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.location}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all_locations')}</option>
              <option value="Remote">{t('filters.remote')}</option>
              <option value="On-site">{t('filters.on_site')}</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.task_type')}</label>
            <select
              name="taskType"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.taskType}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all_types')}</option>
              <option value="One-time">{t('filters.one_time')}</option>
              <option value="Ongoing">{t('filters.ongoing')}</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-semibold mb-2">{t('filters.family_friendly')}</label>
            <select
              name="familyFriendly"
              className="w-full p-2 rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-2 focus:ring-yellow-400"
              style={{
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none'
              }}
              value={filters.familyFriendly}
              onChange={handleFilterChange}
            >
              <option value="">{t('filters.all')}</option>
              <option value="Yes">{t('filters.yes')}</option>
              <option value="No">{t('filters.no')}</option>
            </select>
          </div>
        </div>

        {filteredTasks.length === 0 ? (
          <div className="text-center py-12">{t('suggested_tasks.no_tasks')}</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredTasks.map((task) => (
              <div key={task.id} className="bg-blue-100 rounded-lg shadow-md overflow-hidden"> {/* Light blue background */}
                <img
                  src={task.thumbnail_url || '/default-thumbnail.jpg'}
                  alt={task.title}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-bold text-gray-800 mb-2 truncate">{task.title}</h3>
                  <p className="text-sm text-gray-600 mb-1"><strong>{t('filters.location')}:</strong> {task.is_remote ? t('filters.remote') : task.location}</p>
                  <p className="text-sm text-gray-600 mb-1"><strong>{t('filters.task_type')}:</strong> {task.is_one_time ? t('filters.one_time') : t('filters.ongoing')}</p>
                  <p className="text-sm text-gray-600 mb-1"><strong>{t('filters.family_friendly')}:</strong> {task.is_family_friendly ? t('filters.yes') : t('filters.no')}</p>
                  <p className="text-sm text-gray-600 mb-3 line-clamp-3">{task.description}</p>
                  <button
                    className="w-full bg-yellow-400 text-black px-4 py-2 rounded-full hover:bg-yellow-500 transition-colors duration-200 text-sm"
                    onClick={() => handleLearnMore(task)}
                  >
                    {t('suggested_tasks.learn_more')}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {selectedTask && <TaskModal task={selectedTask} onClose={handleCloseModal} refreshTasks={refreshTasks} />}
      </div>
    </div>
  );
};

export default SuggestedTasks;
