import React from 'react';
import { useTranslation } from 'react-i18next';

const OneTimeOrOngoingChoice = ({ onChoose }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#FFF7D4] py-10 sm:py-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="rounded-lg bg-[#FEE372] p-6 mb-8">
          <h2 className="text-2xl font-bold mb-2">
            <span className="mr-2" role="img" aria-label="Sparkles">✨</span>
            {t('one_time_or_ongoing.title', 'Help whenever you like')}
            <span className="ml-2" role="img" aria-label="Heart">❤️</span>
          </h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <button
            onClick={() => onChoose('One-time')}
            className="bg-white rounded-lg p-4 text-left shadow-lg hover:shadow-xl transition-shadow duration-200 border border-transparent hover:border-yellow-300"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl font-semibold">{t('one_time_or_ongoing.one_time', 'one time')}</span>
              <div className="w-24 h-24 overflow-hidden">
                <img 
                  src="/1.png" 
                  alt="One-time volunteering" 
                  className="w-full h-full object-cover rounded-lg transform -rotate-12"
                />
              </div>
            </div>
          </button>

          <button
            onClick={() => onChoose('Ongoing')}
            className="bg-white rounded-lg p-4 text-left shadow-lg hover:shadow-xl transition-shadow duration-200 border border-transparent hover:border-yellow-300"
          >
            <div className="flex items-center justify-between">
              <span className="text-xl font-semibold">{t('one_time_or_ongoing.ongoing', 'regularly')}</span>
              <div className="w-24 h-24 overflow-hidden">
                <img 
                  src="/2.png" 
                  alt="Regular volunteering" 
                  className="w-full h-full object-cover rounded-lg transform rotate-12"
                />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OneTimeOrOngoingChoice;