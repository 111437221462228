import React from 'react';
import { useTranslation } from 'react-i18next';

const TrustUsSection = () => {
  const { t } = useTranslation();

  return (
    <div className="py-16" style={{ backgroundColor: '#D4E5FB' }}> {/* Custom light blue background */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Three Feature Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature 1 */}
          <div className="text-center">
            <div className="w-16 h-16 mx-auto rounded-full mb-4 flex items-center justify-center" style={{ backgroundColor: '#FFECB3' }}>
              {/* Icon */}
              <span className="text-2xl text-blue-500">🙌</span>
            </div>
            <h3 className="font-bold text-lg">{t('trust.serious_organisations')}</h3>
            <p className="text-gray-500 text-sm">{t('trust.serious_organisations_desc')}</p>
          </div>
          {/* Feature 2 */}
          <div className="text-center">
            <div className="w-16 h-16 mx-auto rounded-full mb-4 flex items-center justify-center" style={{ backgroundColor: '#FFECB3' }}>
              {/* Icon */}
              <span className="text-2xl text-blue-500">🔒</span>
            </div>
            <h3 className="font-bold text-lg">{t('trust.protecting_confidentiality')}</h3>
            <p className="text-gray-500 text-sm">
              {t('trust.protecting_confidentiality_desc')}
            </p>
          </div>
          {/* Feature 3 */}
          <div className="text-center">
            <div className="w-16 h-16 mx-auto rounded-full mb-4 flex items-center justify-center" style={{ backgroundColor: '#FFECB3' }}>
              {/* Icon */}
              <span className="text-2xl text-blue-500">🚀</span>
            </div>
            <h3 className="font-bold text-lg">{t('trust.memorable_experience')}</h3>
            <p className="text-gray-500 text-sm">
              {t('trust.memorable_experience_desc')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustUsSection;