import React, { useState } from 'react';
import { useCMS } from '../contexts/CMSContext';
import { useTranslation } from 'react-i18next'; // Import the translation hook

const Categories = ({ onShowSearch }) => {
  const cmsContent = useCMS();
  const { t } = useTranslation(); // Use the translation hook

  // Use CMS categories if available, otherwise use a default list
  const categories = cmsContent.interestedCategories || [
    "Administration",
    "Animal care",
    "Anything with Food",
    "Educational",
    "Environmental/ Cultural",
    "Family Friendly",
    "Maintenance",
    "Retail",
    "Social/Care giving",
    "Sports related",
    "Other"
  ];

  // Define a mapping of category names to icons
  const categoryIcons = {
    'Administration': '📋',
    'Animal care': '🐾',
    'Anything with Food': '🍽️',
    'Educational': '🎓',
    'Environmental/ Cultural': '🌿',
    'Family Friendly': '👨‍👩‍👧‍👦',
    'Maintenance': '🔧',
    'Retail': '🛒',
    'Social/Care giving': '🤝',
    'Sports related': '🏅',
    'Other': '😊'
  };

  // Pagination state for categories (mobile view)
  const [pageIndex, setPageIndex] = useState(0);
  const categoriesPerPage = 6;

  // Calculate the total number of pages
  const totalPages = Math.ceil(categories.length / categoriesPerPage);

  // Calculate the paginated categories (for mobile view)
  const paginatedCategories = categories.slice(pageIndex * categoriesPerPage, (pageIndex + 1) * categoriesPerPage);

  const handleCategoryClick = (category) => {
    onShowSearch(category);
  };

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  return (
    <div className="bg-[#FFF7D4] py-10 sm:py-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="rounded-lg bg-[#FEE372] p-6 mb-8">
          <h2 className="text-2xl font-bold mb-2">
            <span className="mr-2" role="img" aria-label="Sparkles">✨</span>
            {t('categories.volunteer_for')}
            <span className="ml-2" role="img" aria-label="Heart">❤️</span>
          </h2>
        </div>

        {/* Display categories as grid for larger screens */}
        <div className="hidden sm:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {categories.map((category, index) => (
            <button
              key={index}
              className="bg-white rounded-lg p-4 text-left shadow-lg hover:shadow-xl transition-shadow duration-200 border border-transparent hover:border-yellow-300"
              onClick={() => handleCategoryClick(category)}
            >
              <span className="mr-2 text-lg" role="img" aria-label={category}>
                {categoryIcons[category] || '🔍'}
              </span>
              {t(`categories.${category.toLowerCase().replace(/ /g, '_')}`, category)}
            </button>
          ))}
        </div>

        {/* Display categories with pagination for mobile view */}
        <div className="sm:hidden">
          <div className="grid grid-cols-1 gap-4">
            {paginatedCategories.map((category, index) => (
              <button
                key={index}
                className="bg-white rounded-lg p-4 text-left shadow-lg hover:shadow-xl transition-shadow duration-200 border border-transparent hover:border-yellow-300"
                onClick={() => handleCategoryClick(category)}
              >
                <span className="mr-2 text-lg" role="img" aria-label={category}>
                  {categoryIcons[category] || '🔍'}
                </span>
                {t(`categories.${category.toLowerCase().replace(/ /g, '_')}`, category)}
              </button>
            ))}
          </div>

          {/* Page navigation dots */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }).map((_, i) => (
              <button
                key={i}
                onClick={() => setPageIndex(i)}
                className={`w-3 h-3 mx-1 rounded-full ${i === pageIndex ? 'bg-black' : 'bg-gray-400'}`}
              />
            ))}
          </div>

          {/* Optional: Previous/Next buttons */}
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrevPage}
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
              disabled={pageIndex === 0}
            >
              {t('categories.previous')}
            </button>
            <button
              onClick={handleNextPage}
              className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
              disabled={pageIndex === totalPages - 1}
            >
              {t('categories.next')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
