// ProfileModal.js
import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { useFetchWithAuth } from '../useFetchWithAuth'; // Import fetchWithAuth

const categories = [
  { icon: '📋', name: 'Administration' },
  { icon: '🐾', name: 'Animal care' },
  // Add other categories here
];

const ProfileModal = ({ onClose, onSave }) => {
    const { fetchWithAuth } = useFetchWithAuth(); // Use fetchWithAuth
    const [user, setUser] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [avatar, setAvatar] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { auth_user } = useUser();
    const { t } = useTranslation();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
            setFirstName(storedUser.first_name || '');
            setLastName(storedUser.last_name || '');
            setContactNumber(storedUser.contact_number || '');
            setAddress(storedUser.address || '');
            setDescription(storedUser.self_description || '');
            setSelectedCategories(
                Array.isArray(storedUser.interested_categories)
                    ? storedUser.interested_categories
                    : []
            );
        }
    }, []);

    const handleCategoryClick = (category) => {
        setSelectedCategories(prev => 
            prev.includes(category)
                ? prev.filter(cat => cat !== category)
                : [...prev, category]
        );
    };

    const handleAvatarChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setAvatar(e.target.files[0]);
        }
    };

    const handleSave = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const formData = new FormData();
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('contact_number', contactNumber);
            formData.append('address', address);
            formData.append('self_description', description);

            selectedCategories.forEach((category) => {
                formData.append('interested_categories', category);
            });

            if (avatar) {
                formData.append('avatar', avatar);
            }

            const response = await fetchWithAuth(`https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/users/${user.email}/update`, {
                method: 'POST',
                body: formData,
            });

            const updatedProfile = await response.json();
            const updatedUser = { ...user, ...updatedProfile };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            onClose();
        } catch (err) {
            setError(err.message);
            console.error('Error updating profile:', err);
        } finally {
            setIsLoading(false);
        }
    };

    if (!user) {
        return <div>{t('profile.loading')}</div>;
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg max-w-lg w-full">
                <div className="p-6 max-h-[70vh] overflow-y-auto">
                    <h2 className="text-2xl font-bold mb-4">{t('profile.update_profile')}</h2>

                    {error && <p className="text-red-500 mb-4">{error}</p>}

                    <p className="text-sm text-gray-600 mb-4">{t('profile.keep_updated')}</p>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('profile.first_name')}</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="w-full p-2 border rounded"
                            placeholder={t('profile.enter_first_name')}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('profile.last_name')}</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="w-full p-2 border rounded"
                            placeholder={t('profile.enter_last_name')}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('profile.contact_number')}</label>
                        <input
                            type="text"
                            value={contactNumber}
                            onChange={(e) => setContactNumber(e.target.value)}
                            className="w-full p-2 border rounded"
                            placeholder={t('profile.enter_contact_number')}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('profile.address')}</label>
                        <input
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="w-full p-2 border rounded"
                            placeholder={t('profile.enter_address')}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('profile.self_description')}</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full p-2 border rounded"
                            placeholder={t('profile.tell_us_more')}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('profile.profile_picture')}</label>
                        <input
                            type="file"
                            onChange={handleAvatarChange}
                            accept="image/jpeg,image/png,image/gif"
                            className="w-full p-2 border rounded"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2">{t('profile.interested_categories')}</label>
                        <div className="grid grid-cols-2 gap-2">
                            {categories.map((category, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className={`p-2 rounded-lg ${selectedCategories.includes(category.name) ? 'bg-yellow-400' : 'bg-gray-100'} hover:bg-yellow-300`}
                                    onClick={() => handleCategoryClick(category.name)}
                                >
                                    {category.icon} {category.name}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex justify-end p-4 bg-gray-100">
                    <button
                        onClick={onClose}
                        className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-400 transition"
                        disabled={isLoading}
                    >
                        {t('profile.cancel')}
                    </button>
                    <button
                        onClick={handleSave}
                        className="bg-yellow-400 text-black px-4 py-2 rounded-full hover:bg-yellow-500 transition"
                        disabled={isLoading}
                    >
                        {isLoading ? t('profile.saving') : t('profile.save_changes')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileModal;
