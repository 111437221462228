import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useFetchWithAuth } from '../useFetchWithAuth'; // Use the fetchWithAuth hook

const TaskModal = ({ task, onClose, refreshTasks }) => {
  const { user } = useUser();
  const { fetchWithAuth } = useFetchWithAuth(); // Use the fetchWithAuth hook for API calls
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [userHasEnrolled, setUserHasEnrolled] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);

  const isAuthenticated = user && user.authToken !== null;

  useEffect(() => {
    if (task.geo_location && task.geo_location.lat && task.geo_location.lng) {
      setMapCenter({
        lat: task.geo_location.lat,
        lng: task.geo_location.lng
      });
    }

    if (!task || !user) return;

    const currentUserId = user.userId;
    if (!currentUserId) return;

    const enrolledInGiverApplications = task.giver_applications?.some(appId => appId === currentUserId);
    const enrolledInAcceptedGivers = task.accepted_givers?.some(giverId => giverId === currentUserId);

    setUserHasEnrolled(enrolledInGiverApplications || enrolledInAcceptedGivers);
  }, [task, user]);

  const handleEnroll = async () => {
    if (!user) return;

    const userProfile = {
      id: user.userId,
      first_name: user.first_name,
      last_name: user.last_name,
      object_id: user.object_id,
      contact_number: user.contact_number,
      rating: 5,
      prof_pic_path: user.prof_pic_path,
    };

    setIsLoading(true);
    try {
      const response = await fetchWithAuth(
        `https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/tasks/${task.id}/add_giver`, 
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userProfile),
        }
      );

      if (!response.ok) throw new Error(t('task_modal.errors.enroll_failed'));
      await refreshTasks();
      setIsSuccess(true);
    } catch (error) {
      setError(error.message);
      console.error(t('task_modal.errors.enroll_error'), error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackOut = async () => {
    if (!user) return;

    setIsLoading(true);
    try {
      const response = await fetchWithAuth(
        `https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/tasks/${task.id}/remove_giver`, 
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) throw new Error(t('task_modal.errors.back_out_failed'));
      await refreshTasks();
      setIsSuccess(true);

      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      setError(error.message);
      console.error(t('task_modal.errors.back_out_error'), error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!task) return null;

  const { title, description, location, organisation_details, tags, is_family_friendly } = task;

  const mapContainerStyle = {
    width: '100%',
    height: '200px'
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50 p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl p-4 sm:p-6 overflow-y-auto max-h-full">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-gray-900">{title}</h2>

        <div className="mb-4">
          <h3 className="font-semibold text-lg sm:text-xl text-gray-800">{t('task_modal.description')}</h3>
          <p className="text-gray-700 leading-relaxed">{description}</p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold text-lg sm:text-xl text-gray-800">{t('task_modal.location')}</h3>
          <p className="text-gray-600 mb-2">{location}</p>
          {mapCenter && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={mapCenter}
              zoom={14}
            >
              <Marker position={mapCenter} />
            </GoogleMap>
          )}
        </div>

        <div className="mb-4">
          <h3 className="font-semibold text-lg sm:text-xl text-gray-800">{t('filters.family_friendly')}</h3>
          <p className="text-gray-600 mb-2">
            {is_family_friendly ? t('filters.yes') : t('filters.no')}
          </p>
        </div>

        {organisation_details && (
          <div className="mb-6">
            <h3 className="font-semibold text-lg sm:text-xl text-gray-800">{t('task_modal.organization_info')}</h3>
            <div className="border-l-4 border-yellow-400 pl-4">
              <p className="text-gray-700">
                <span className="font-bold">{t('task_modal.org_name')}: </span>{organisation_details.organisation_name}
              </p>
              <p className="text-gray-700">
                <span className="font-bold">{t('task_modal.org_description')}: </span>{organisation_details.organisation_description}
              </p>
              <p className="text-gray-700">
                <span className="font-bold">{t('task_modal.org_contact')}: </span>{organisation_details.organisation_contact}
              </p>
              <p className="text-gray-700">
                <span className="font-bold">{t('task_modal.org_email')}: </span>{organisation_details.organisation_email}
              </p>
              <p className="text-gray-700">
                <span className="font-bold">{t('task_modal.org_location')}: </span>{organisation_details.organisation_location}
              </p>
            </div>
          </div>
        )}

        <div className="mb-6">
          <h3 className="font-semibold text-lg sm:text-xl text-gray-800">{t('task_modal.tags')}</h3>
          <div className="flex flex-wrap gap-2">
            {tags && tags.length > 0 ? (
              tags.map((tag, index) => (
                <span key={index} className="bg-yellow-200 text-sm px-3 py-1 rounded-full">
                  {tag}
                </span>
              ))
            ) : (
              <p className="text-gray-600">{t('task_modal.no_tags')}</p>
            )}
          </div>
        </div>

        <div className="mb-6">
          {userHasEnrolled ? (
            <div className="flex flex-col space-y-4">
              <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                <p className="font-bold">{t('task_modal.enrolled')}</p>
                <p>{t('task_modal.enrolled_desc')}</p>
              </div>
              <button
                onClick={handleBackOut}
                disabled={isLoading}
                className="bg-red-400 text-white px-6 py-3 rounded-full hover:bg-red-500 transition-colors duration-200 disabled:opacity-50"
              >
                {isLoading ? t('task_modal.backing_out') : t('task_modal.back_out')}
              </button>
            </div>
          ) : !isSuccess ? (
            <>
              {error && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                  <p className="font-bold">{t('task_modal.error')}</p>
                  <p>{error}</p>
                </div>
              )}
              {isAuthenticated ? (
                <button
                  onClick={handleEnroll}
                  disabled={isLoading}
                  className="bg-yellow-400 text-black px-6 py-3 rounded-full hover:bg-yellow-500 transition-colors duration-200 disabled:opacity-50 w-full sm:w-auto"
                >
                  {isLoading ? t('task_modal.enrolling') : t('task_modal.count_me_in')}
                </button>
              ) : (
                <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
                  <p className="font-bold">{t('task_modal.info')}</p>
                  <p>{t('task_modal.sign_in_to_participate')}</p>
                </div>
              )}
            </>
          ) : (
            <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
              <p className="font-bold">{t('task_modal.success')}</p>
              <p>{t('task_modal.request_noted')}</p>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-yellow-400 text-black px-6 py-3 rounded-full hover:bg-yellow-500 transition-colors duration-200 w-full sm:w-auto"
          >
            {t('task_modal.close')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;
