import React from 'react';
import { useCMS } from '../contexts/CMSContext';
import { useTranslation } from 'react-i18next';

const TestimonialsSection = () => {
  const { testimonials } = useCMS();
  const { t } = useTranslation();

  // Function to extract video ID and platform from URL
  const getVideoInfo = (url) => {
    if (url.includes('youtu.be') || url.includes('youtube.com')) {
      // YouTube (including Shorts)
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|shorts\/)([^#&?]*).*/;
      const match = url.match(regExp);
      return { platform: 'youtube', id: (match && match[2].length === 11) ? match[2] : null };
    } else if (url.includes('vimeo.com')) {
      // Vimeo
      const regExp = /vimeo\.com\/([0-9]+)/;
      const match = url.match(regExp);
      return { platform: 'vimeo', id: match ? match[1] : null };
    }
    return { platform: null, id: null };
  };

  // Function to generate embed URL
  const getEmbedUrl = (videoInfo) => {
    switch (videoInfo.platform) {
      case 'youtube':
        return `https://www.youtube.com/embed/${videoInfo.id}`;
      case 'vimeo':
        return `https://player.vimeo.com/video/${videoInfo.id}`;
      default:
        return '';
    }
  };

  return (
    <div className="bg-[#FFF7D4] py-10 sm:py-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="rounded-lg bg-[#FFECB3] p-6 mb-8">
          <h2 className="text-3xl font-bold text-gray-800">
            {t('testimonials.title')}
            <span className="ml-2" role="img" aria-label="Smile">😊</span>
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => {
            const videoInfo = getVideoInfo(testimonial.video_url);
            const embedUrl = getEmbedUrl(videoInfo);
            return (
              <div key={index} className="bg-white rounded-lg overflow-hidden shadow-md">
                <div className="aspect-w-9 aspect-h-16">
                  {embedUrl && (
                    <iframe
                      src={embedUrl}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full"
                      title={t('testimonials.videoTitle', { name: testimonial.name })}
                    ></iframe>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold text-center">{testimonial.name}</h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;